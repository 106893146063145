<template>
    <IssueDrawer v-model:issueId="issueId" :batchIssueData="formData" @onEmitOk="handleCancel" />
    <a-modal
        v-model:visible="visible"
        title="批量下发"
        @cancel="handleCancel"
        width="600px"
        :footer="null"
        :destroyOnClose="true"
        :zIndex="999"
    >
        <a-form
            ref="formRef"
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item name="containerNo" :rules="[{ validator: checkContainerNo }]">
                <template v-slot:label>
                    <span class="require-star">*</span>
                    集成编号
                </template>
                <a-form-item>
                    <a-input-number
                        v-model:value="formData.containerNoFrom"
                        :min="0"
                        :step="1"
                        :precision="0"
                        style="width: 190px"
                        @change="value => (formData.containerNo[0] = value)"
                    />
                    <span style="margin: 0 10px">至</span>
                    <a-input-number
                        v-model:value="formData.containerNoTo"
                        :min="0"
                        :step="1"
                        :precision="0"
                        style="width: 190px"
                        @change="value => (formData.containerNo[1] = value)"
                    />
                </a-form-item>
            </a-form-item>

            <a-form-item label="工单数量" name="count">
                <a-input v-model:value="comCount" :disabled="true" style="width: 100%" />
            </a-form-item>

            <a-form-item label="工单类别" name="category">
                <Select
                    v-model:value="formData.category"
                    :options="workorderCatrgoryOptions"
                    labelKey="name"
                    valueKey="code"
                    style="width: 100%"
                    :labelInValue="true"
                />
            </a-form-item>

            <a-form-item label="计划投产日期" name="scheduledStartDate">
                <a-date-picker
                    v-model:value="formData.scheduledStartDate"
                    valueFormat="YYYY-MM-DD"
                    style="width: 100%"
                />
            </a-form-item>

            <a-form-item label="计划完工日期" name="scheduledEndDate">
                <a-date-picker
                    v-model:value="formData.scheduledEndDate"
                    valueFormat="YYYY-MM-DD"
                    style="width: 100%"
                />
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 17, span: 7 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs, computed } from "vue";
import { apiDataDictionaryList } from "@/api";
import { showMessage } from "@/utils/common";
import Select from "@/components/Select";
import { CloseCircleOutlined } from "@ant-design/icons-vue";
import IssueDrawer from "../issueDrawer";
import moment from "moment";
export default defineComponent({
    props: ["batchIssueRow"],
    emits: ["onEmitOk", "update:batchIssueRow"],
    components: {
        Select,
        CloseCircleOutlined,
        IssueDrawer,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            formRef: null,
            issueId: null,
            workorderCatrgoryOptions: [],
            formData: {
                containerNo: [],
                containerNoFrom: null,
                containerNoTo: null,
                category: "",
                scheduledStartDate: "",
                scheduledEndDate: "",
            },
            comCount: computed(() => {
                const { containerNoFrom, containerNoTo } = state.formData;
                if (
                    containerNoFrom != null &&
                    containerNoTo != null &&
                    containerNoTo >= containerNoFrom
                ) {
                    return containerNoTo - containerNoFrom + 1;
                } else {
                    return "";
                }
            }),
        });

        const handleCancel = () => {
            state.visible = false;
            state.formRef.resetFields();
            state.formData.containerNoFrom = null;
            state.formData.containerNoTo = null;
            emit("update:batchIssueRow", null);
            emit("onEmitOk");
        };

        const onFinish = async () => {
            const { containerNoFrom, containerNoTo, scheduledStartDate, scheduledEndDate } =
                state.formData;
            const diff = containerNoTo - containerNoFrom;
            if (diff < 0) {
                return showMessage("info", "编号范围右侧应大于左侧");
            }
            if (state.comCount > props?.batchIssueRow?.quantity) {
                return showMessage("info", "工单数量不能大于产品数量");
            }
            if (moment(scheduledStartDate).isAfter(scheduledEndDate)) {
                return showMessage("info", "投产日期不能大于完成日期");
            }
            state.issueId = props.batchIssueRow?.id;
        };

        const checkContainerNo = (rule, value) => {
            if (value?.includes(null) || value?.length != 2) {
                return Promise.reject("请输入集成编号");
            } else {
                return Promise.resolve();
            }
        };

        const getIndexData = async () => {
            let res = await apiDataDictionaryList({ pCode: "GD" });
            if (res.status === "SUCCESS") {
                state.workorderCatrgoryOptions = res?.data;
            }
        };
        getIndexData();

        watch(
            () => props.batchIssueRow,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) getIndexData();
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkContainerNo,
        };
    },
});
</script>

<style lang="less" scoped>
.require-star {
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
}
</style>
