<template>
    <div class="container">
        <UpDateModal v-model:upDateId="upDateId" @eventUpdateOk="eventUpdateOk" />
        <DetailDrawer v-model:detailId="detailId" />
        <IssueDrawer v-model:issueId="issueId" @onEmitOk="getTabelData" />
        <BatchIssue v-model:batchIssueRow="batchIssueRow" @onEmitOk="getTabelData" />
        <a-tabs v-model:activeKey="status" @change="onChangeTab">
            <a-tab-pane
                v-for="item in statusCodeAndNames"
                :key="item.code"
                :tab="item.name + '订单'"
            ></a-tab-pane>
        </a-tabs>
        <FilterBlock @search="search" />
        <div class="table-content">
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 1200 }"
                :destroyOnClose="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'orderStartProductCount'">
                        <span
                            :style="{
                                color:
                                    record.orderStartProductCount == record.quantity
                                        ? '#389e0d'
                                        : '#d46b08',
                            }"
                        >
                            {{ record.orderStartProductCount }}
                        </span>
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="() => (detailId = record.id)">详情</a>
                            <a-space
                                v-show="status == 'WAIT_START'"
                                direction="horizontal"
                                :size="5"
                            >
                                <a
                                    @click="() => (issueId = record.id)"
                                    v-perm="'btn:mes-orders-send'"
                                >
                                    下发
                                </a>
                                <a
                                    @click="() => (batchIssueRow = record)"
                                    v-show="record.productionLineType == 'INTEGRATED'"
                                    v-perm="'btn:mes-orders-send'"
                                >
                                    批量下发
                                </a>
                                <a
                                    @click="() => (upDateId = record.id)"
                                    v-perm="'btn:mes-orders-edit'"
                                >
                                    编辑
                                </a>
                                <a
                                    @click="handleDelete(record.id)"
                                    v-perm="'btn:mes-orders-delete'"
                                >
                                    删除
                                </a>
                            </a-space>
                            <a-space v-show="status == 'STARTED'" direction="horizontal" :size="5">
                                <a
                                    @click="handleTerminate(record.id)"
                                    v-perm="'btn:mes-orders-terminated'"
                                >
                                    终止
                                </a>
                                <a
                                    @click="handleComplete(record.id)"
                                    v-perm="'btn:mes-orders-completed'"
                                >
                                    完成
                                </a>
                            </a-space>
                        </a-space>
                    </template>
                </template>
                <template #title>
                    <div class="operate-content">
                        <a-button></a-button>
                        <a-button
                            v-perm="'btn:mes-orders-add'"
                            type="primary"
                            @click="() => (upDateId = '')"
                        >
                            新增
                        </a-button>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import UpDateModal from "./upDateModal";
import DetailDrawer from "./detailDrawer";
import IssueDrawer from "./issueDrawer";
import BatchIssue from "./batchIssue";
import { confirmModal, showMessage } from "@/utils/common";
import {
    apiProductOrderList,
    apiProductOrderIndexData,
    apiProductOrderDelete,
    apiProductOrderComplete,
    apiProductOrderTerminate,
} from "@/api";
export default defineComponent({
    components: {
        FilterBlock,
        UpDateModal,
        DetailDrawer,
        IssueDrawer,
        BatchIssue,
    },
    setup() {
        const columns = [
            {
                title: "序号",
                dataIndex: "index",
                width: 60,
                fixed: "left",
            },
            {
                title: "生产订单号",
                dataIndex: "produceOrderCode",
            },
            {
                title: "销售订单号",
                dataIndex: "salesOrderCode",
            },
            {
                title: "优先级",
                dataIndex: "priorityDesc",
            },
            {
                title: "产线类型",
                dataIndex: "productionLineTypeDesc",
            },
            {
                title: "项目名称",
                dataIndex: "projectName",
            },
            {
                title: "项目编码",
                dataIndex: "projectCode",
            },
            {
                title: "产品名称",
                dataIndex: "productName",
            },
            {
                title: "产品编码",
                dataIndex: "productCode",
                width: 100,
            },
            {
                title: "单位",
                dataIndex: "unit",
                width: 100,
            },
            {
                title: "生产数量",
                dataIndex: "quantity",
                width: 100,
            },
            {
                title: "下发数量",
                dataIndex: "orderStartProductCount",
            },
            {
                title: "预计开始日期",
                dataIndex: "scheduledStartDate",
            },
            {
                title: "预计完成日期",
                dataIndex: "scheduledEndDate",
            },
            {
                title: "操作",
                dataIndex: "operate",
                fixed: "right",
                width: 250,
            },
        ];

        const state = reactive({
            status: "",
            upDateId: null,
            batchIssueRow: false,
            statusCodeAndNames: [],
            detailId: null,
            issueId: null,
            params: null,
            data: [],
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiProductOrderList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                    status: state.status,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.data = res.data.recordList;
            }
        };

        const onChangeTab = () => {
            state.pagination.current = 1;
            getTabelData();
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        const eventUpdateOk = () => {
            getTabelData();
        };

        const getIndexData = async () => {
            let res = await apiProductOrderIndexData();
            if (res.status === "SUCCESS") {
                const { statusCandidate } = res?.data;
                state.statusCodeAndNames = statusCandidate;
                if (statusCandidate?.length) {
                    state.status = statusCandidate[0]?.code;
                    getTabelData();
                }
            }
        };

        getIndexData();

        const handleOpenDetail = id => {
            state.detailId = id;
        };

        const handleDelete = id => {
            confirmModal({
                type: "warning",
                content: "订单删除后将无法恢复，是否确认删除该订单？",
                onOk: async () => {
                    let res = await apiProductOrderDelete([id]);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        const handleTerminate = id => {
            confirmModal({
                type: "warning",
                content: "订单终止后状态将不可变更，是否确认终止？",
                onOk: async () => {
                    let res = await apiProductOrderTerminate(id);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        const handleComplete = id => {
            confirmModal({
                type: "warning",
                content: "订单完成后状态将不可变更，是否确认完成？",
                onOk: async () => {
                    let res = await apiProductOrderComplete(id);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        return {
            columns,
            ...toRefs(state),
            search,
            paginationChange,
            eventUpdateOk,
            onChangeTab,
            handleOpenDetail,
            handleDelete,
            handleTerminate,
            handleComplete,
            getTabelData,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
:deep(.ant-tabs-nav) {
    background: #fff;
    margin: 0;
    padding: 0 16px;
    box-sizing: border-box;
}
.container {
    // display: none;
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>
