<template>
    <a-drawer
        v-model:visible="visible"
        :closable="false"
        :maskClosable="false"
        :title="`${comIsJcType ? '集成' : '车间'}订单下发`"
        :destroyOnClose="true"
        height="100%"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleSubmit">提交</a-button>
            </a-space>
        </template>
        <a-descriptions title="订单信息">
            <a-descriptions-item label="生产订单号">
                {{ orderInfo?.produceOrderCode }}
            </a-descriptions-item>
            <a-descriptions-item label="优先级">
                {{ orderInfo?.priorityDesc }}
            </a-descriptions-item>
            <a-descriptions-item label="销售订单号">
                {{ orderInfo?.salesOrderCode }}
            </a-descriptions-item>
            <a-descriptions-item label="项目名称">
                {{ orderInfo?.projectName }}
            </a-descriptions-item>
            <a-descriptions-item label="项目编码">
                {{ orderInfo?.projectCode }}
            </a-descriptions-item>
            <a-descriptions-item label="订单类别">
                {{ orderInfo?.categoryName }}
            </a-descriptions-item>
            <a-descriptions-item label="生产数量">
                {{ orderInfo?.quantity }}
            </a-descriptions-item>
            <a-descriptions-item label="计划开始日期">
                {{ orderInfo?.scheduledStartDate }}
            </a-descriptions-item>
            <a-descriptions-item label="计划完成日期">
                {{ orderInfo?.scheduledEndDate }}
            </a-descriptions-item>
        </a-descriptions>
        <div class="desc"></div>
        <a-descriptions title="工单列表"></a-descriptions>
        <a-table
            :columns="comColumns"
            :data-source="
                tableData.slice(
                    (pagination?.current - 1) * pagination?.pageSize,
                    pagination?.current * pagination?.pageSize
                )
            "
            @change="paginationChange"
            bordered
            size="small"
            :pagination="pagination"
            :scroll="{ x: 1500 }"
        >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'index'">
                    {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
                </template>
                <template v-if="column.dataIndex === 'code'">
                    <a-input v-model:value="record.code" :disabled="true" />
                </template>
                <template v-if="column.dataIndex === 'category'">
                    <Select
                        v-model:value="record.category"
                        :options="workorderCatrgoryOptions"
                        labelKey="name"
                        valueKey="code"
                        style="width: 100%"
                        :labelInValue="true"
                    />
                </template>
                <template v-if="column.dataIndex === 'productName'">
                    <a-input v-model:value="record.productName" :disabled="true" />
                </template>
                <template v-if="column.dataIndex === 'productCode'">
                    <a-input v-model:value="record.productCode" :disabled="true" />
                </template>
                <template v-if="column.dataIndex === 'spec'">
                    <a-input v-model:value="record.spec" :disabled="true" />
                </template>
                <template v-if="column.dataIndex === 'productionBatchNo'">
                    <a-input v-model:value="record.productionBatchNo" />
                </template>
                <template v-if="column.dataIndex === 'taskQuantity'">
                    <a-input-number
                        v-model:value="record.taskQuantity"
                        :min="1"
                        :step="1"
                        :precision="0"
                        style="width: 100%"
                    />
                </template>
                <template v-if="column.dataIndex === 'taskQuantityUnit'">
                    <a-input v-model:value="record.taskQuantityUnit" :disabled="true" />
                </template>
                <template v-if="column.dataIndex === 'mesProductTraceCode'">
                    <a-input v-model:value="record.mesProductTraceCode" :disabled="true" />
                </template>
                <template v-if="column.dataIndex === 'containerNo'">
                    <a-input v-model:value="record.containerNo" />
                </template>
                <template v-if="column.dataIndex === 'scheduledStartDate'">
                    <a-date-picker
                        v-model:value="record.scheduledStartDate"
                        valueFormat="YYYY-MM-DD"
                        style="width: 100%"
                    />
                </template>
                <template v-if="column.dataIndex === 'scheduledEndDate'">
                    <a-date-picker
                        v-model:value="record.scheduledEndDate"
                        valueFormat="YYYY-MM-DD"
                        style="width: 100%"
                    />
                </template>
                <template v-if="column.dataIndex === 'remarks'">
                    <a-input v-model:value="record.remarks" />
                </template>
                <template v-if="column.dataIndex === 'operate'">
                    <a-space direction="horizontal" :size="5">
                        <a @click="handleReset(index)">重置</a>
                        <a @click="handleDelete(index)">删除</a>
                    </a-space>
                </template>
            </template>
        </a-table>
        <div class="addicon-wrapper" v-show="tableData.length < orderInfo?.quantity">
            <a-space direction="horizontal" :size="5" @click="handleAddWorkOrder">
                <plus-circle-outlined size="20" />
                <span>添加工单</span>
            </a-space>
        </div>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs, computed, onMounted, nextTick } from "vue";
import { apiProductOrderWorkOrderInfo, apiProductOrderIssue } from "@/api";
import { showMessage, confirmModal, getAutoCreateCode } from "@/utils/common";
import { PlusCircleOutlined } from "@ant-design/icons-vue";
import Select from "@/components/Select";
import moment from "moment";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: "工单号",
        dataIndex: "code",
        width: 200,
    },
    {
        title: "工单类别",
        dataIndex: "category",
    },
    {
        title: "产品名称",
        dataIndex: "productName",
    },
    {
        title: "产品编码",
        dataIndex: "productCode",
        width: 150,
    },
    {
        title: "规格型号",
        dataIndex: "spec",
    },
    {
        title: "计划投产日期",
        dataIndex: "scheduledStartDate",
    },
    {
        title: "计划完工日期",
        dataIndex: "scheduledEndDate",
    },
    {
        title: "备注",
        dataIndex: "remarks",
    },
    {
        title: "操作",
        dataIndex: "operate",
        fixed: "right",
        width: 100,
    },
];

const cjColumns = [
    {
        title: (
            <span>
                <span class="color">*</span>
                生产批次号
            </span>
        ),
        dataIndex: "productionBatchNo",
    },
    {
        title: (
            <span>
                <span class="color">*</span>
                任务数量
            </span>
        ),
        dataIndex: "taskQuantity",
    },
    {
        title: (
            <span>
                <span class="color">*</span>
                单位
            </span>
        ),
        dataIndex: "taskQuantityUnit",
    },
];

const jcColumns = [
    {
        title: "产成品追溯码",
        dataIndex: "mesProductTraceCode",
        width: 150,
    },
    {
        title: (
            <span>
                <span class="color">*</span>
                集成编号
            </span>
        ),
        dataIndex: "containerNo",
    },
];

const cjTableItem = {
    productionBatchNo: "",
    taskQuantity: "",
    taskQuantityUnit: "",
};

const jcTableItem = {
    mesProductTraceCode: "",
    containerNo: "",
};

export default defineComponent({
    props: ["issueId", "batchIssueData"],
    emits: ["update:issueId"],
    components: {
        PlusCircleOutlined,
        Select,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            tableData: [],
            orderInfo: null,
            comIsJcType: computed(() => state?.orderInfo?.productionLineType == "INTEGRATED"),
            comColumns: computed(() => {
                const _columns = [...columns];
                if (state.comIsJcType) {
                    _columns.splice(6, 0, ...jcColumns);
                } else {
                    _columns.splice(6, 0, ...cjColumns);
                }
                nextTick(() => {
                    document.querySelectorAll(".color").forEach(elem => (elem.style.color = "red"));
                });
                return _columns;
            }),
            workorderCatrgoryOptions: [],
            baseTableItem: {
                code: "",
                category: undefined,
                categoryCode: "",
                categoryName: "",
                productName: "",
                productCode: "",
                spec: "",
                scheduledStartDate: "",
                scheduledEndDate: "",
                remarks: "",
            },
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
        };

        const getOrderData = async id => {
            let res = await apiProductOrderWorkOrderInfo(id);
            if (res.status === "SUCCESS") {
                const { mesOrders, categoryCandidate } = res?.data;
                state.orderInfo = mesOrders;
                state.workorderCatrgoryOptions = categoryCandidate || [];
                init();
            }
        };

        const handleAddWorkOrder = async () => {
            state.pagination?.total + 1;
            const length = state.tableData?.length;
            state.tableData.push({
                index: length,
                ...state.baseTableItem,
                ...(state.comIsJcType ? jcTableItem : cjTableItem),
                productName: state?.orderInfo?.productName,
                productCode: state?.orderInfo?.productCode,
                spec: state?.orderInfo?.spec,
                taskQuantityUnit: state?.orderInfo?.unit,
                code: await getAutoCreateCode(
                    state.comIsJcType ? "MES_CJ_WORK_ORDER" : "MES_WORK_ORDER"
                ),
                mesProductTraceCode: await getAutoCreateCode("MES_WORK_ORDER_SN_NO"),
            });
            state.pagination.total = state.tableData?.length;
            state.pagination.current = Math.ceil(
                state.pagination.total / state.pagination.pageSize
            );
        };

        const handleCancel = () => {
            state.tableData = [];
            emit("update:issueId");
        };

        const handleSubmit = async () => {
            if (!state.tableData.length) return showMessage("info", "请添加工单");
            let isHasNull = false,
                isStartDateThenEndDate = false;
            for (let item of state.tableData) {
                item.categoryName = item?.category?.option?.label;
                item.categoryCode = item?.category?.option?.value;
                if (item.containerNo == "") {
                    isHasNull = true;
                    break;
                }
                if (
                    !state.comIsJcType &&
                    (item.productionBatchNo == "" ||
                        item.taskQuantity == "" ||
                        item.taskQuantityUnit == "")
                ) {
                    isHasNull = true;
                    break;
                }
                if (moment(item?.scheduledStartDate).isAfter(item?.scheduledEndDate)) {
                    isStartDateThenEndDate = true;
                    return showMessage("info", "投产日期不能大于完成日期");
                    break;
                }
            }
            if (!state.comIsJcType && state.tableData.length) {
                const totalTaskNum = state.tableData
                    .map(item => item.taskQuantity)
                    .reduce((prev, curr) => prev + curr);
                if (totalTaskNum > state.orderInfo?.quantity) {
                    return showMessage("info", "工单任务数量总和不能大于生产数量");
                }
            }
            if (isHasNull) return showMessage("info", "请完善表格数据，红色标注字段为必填项");
            confirmModal({
                type: "warning",
                content: "订单下发后不可编辑修改内容，是否确认下发？",
                zIndex: 10002,
                onOk: async () => {
                    const { orderInfo, tableData } = state;
                    let res = await apiProductOrderIssue(
                        {
                            orderId: props.issueId,
                            workOrderItems: tableData,
                        },
                        orderInfo?.productionLineType
                    );
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        handleCancel();
                        emit("onEmitOk");
                    }
                },
            });
        };

        const handleReset = index => {
            state.tableData[index] = {
                ...state.baseTableItem,
                ...(state.comIsJcType ? jcTableItem : cjTableItem),
                productName: state?.orderInfo?.productName,
                productCode: state?.orderInfo?.productCode,
                spec: state?.orderInfo?.spec,
            };
        };

        const handleDelete = index => {
            state.tableData.splice(index, 1);
            state.pagination.total = state.tableData?.length;
            state.pagination.current = Math.ceil(
                state.pagination.total / state.pagination.pageSize
            );
        };

        watch(
            () => props.issueId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getOrderData(newVal);
                }
            }
        );

        const init = async () => {
            const {
                category,
                containerNoFrom,
                containerNoTo,
                scheduledStartDate,
                scheduledEndDate,
            } = props?.batchIssueData;
            const diff = containerNoTo - containerNoFrom;
            state.pagination.total = diff;
            for (let i = 0; i <= diff; i++) {
                state.tableData.push({
                    index: i,
                    ...state.baseTableItem,
                    ...(state.comIsJcType ? jcTableItem : cjTableItem),
                    productName: state?.orderInfo?.productName,
                    productCode: state?.orderInfo?.productCode,
                    spec: state?.orderInfo?.spec,
                    taskQuantityUnit: state?.orderInfo?.unit,
                    code: await getAutoCreateCode(
                        state.comIsJcType ? "MES_WORK_ORDER" : "MES_CJ_WORK_ORDER"
                    ),
                    mesProductTraceCode: await getAutoCreateCode("MES_WORK_ORDER_SN_NO"),
                    containerNo: containerNoFrom + i,
                    category,
                    scheduledStartDate,
                    scheduledEndDate,
                });
            }
        };

        watch(
            () => props?.batchIssueData,
            async newValue => {},
            {
                deep: true,
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            handleSubmit,
            handleAddWorkOrder,
            handleReset,
            handleDelete,
            paginationChange,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
}
.operate-content {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.addicon-wrapper {
    margin: 16px 0;
    text-align: center;
    .ant-space {
        cursor: pointer;
    }
}
.no-enter-tips {
    color: red;
}
</style>
