<template>
    <ProductList v-model:productListVisible="productListVisible" @onSelect="onSelectProduct" />
    <SaleOrder v-model:saleOrderVisible="saleOrderVisible" @onSelect="onSelectSaleOrder" />
    <OrderCategory
        :data="orderCategoryOptions"
        typeName="订单"
        v-model:categoryVisible="orderCategoryVisible"
        @onSelect="onChangeOrderCategory"
    />
    <a-modal
        v-model:visible="visible"
        :title="`${upDateId ? '编辑' : '新增'}订单`"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="1150px"
    >
        <a-form
            :model="formData"
            name="basic"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-row :span="24" class="margin-bottom">
                <a-col :span="12">
                    <a-form-item
                        label="生产订单号"
                        name="produceOrderCode"
                        :rules="[
                            { required: !autoCreateOrder, message: '请输入生产订单号' },
                            { validator: checkSpaceStartEnd },
                        ]"
                    >
                        <a-form-item>
                            <div class="contant-item">
                                <a-input
                                    style="width: 240px"
                                    :disabled="autoCreateOrder || Boolean(upDateId)"
                                    v-model:value="formData.produceOrderCode"
                                    :maxlength="100"
                                    showCount
                                ></a-input>
                                <a-checkbox
                                    :disabled="Boolean(upDateId)"
                                    v-model:checked="autoCreateOrder"
                                    @change="changeAutoCreateOrder"
                                    v-if="!upDateId"
                                >
                                    系统生成
                                </a-checkbox>
                            </div>
                        </a-form-item>
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item
                        label="销售订单号"
                        name="salesOrderCode"
                        :rules="[
                            { required: !bindSaleOrder, message: '请输入销售订单号' },
                            { validator: checkSpaceStartEnd },
                        ]"
                    >
                        <a-form-item>
                            <div class="contant-item">
                                <a-input
                                    style="width: 150px"
                                    :disabled="true"
                                    v-model:value="formData.salesOrderCode"
                                    :maxlength="100"
                                    showCount
                                ></a-input>
                                <a-button
                                    :disabled="bindSaleOrder"
                                    type="primary"
                                    @click="() => (saleOrderVisible = true)"
                                >
                                    查询
                                </a-button>
                                <a-checkbox
                                    v-model:checked="bindSaleOrder"
                                    @change="changeBindSaleOrder"
                                >
                                    不绑定销售订单
                                </a-checkbox>
                            </div>
                        </a-form-item>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :span="24" class="margin-bottom">
                <a-col :span="12">
                    <a-form-item
                        label="项目名称"
                        name="projectName"
                        :rules="[
                            { required: true, message: '请输入项目名称' },
                            { validator: checkSpaceStartEnd },
                        ]"
                    >
                        <a-input v-model:value="formData.projectName" :maxlength="100" showCount />
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item
                        label="项目编码"
                        name="projectCode"
                        :rules="[
                            { required: true, message: '请输入项目编码' },
                            { validator: checkSpaceStartEnd },
                        ]"
                    >
                        <a-input v-model:value="formData.projectCode" :maxlength="100" showCount />
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :span="24" class="margin-bottom">
                <a-col :span="12">
                    <a-form-item
                        label="优先级"
                        name="priority"
                        :rules="[{ required: true, message: '请选择优先级' }]"
                    >
                        <Select
                            v-model:value="formData.priority"
                            :options="priorityOptinos"
                            labelKey="name"
                            valueKey="code"
                        />
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item
                        label="产线类型"
                        name="productionLineType"
                        :rules="[{ required: true, message: '请选择产线类型' }]"
                    >
                        <Select
                            v-model:value="formData.productionLineType"
                            :options="productionLineTypeOptions"
                            labelKey="name"
                            valueKey="code"
                        />
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :span="24" class="margin-bottom">
                <a-col :span="12">
                    <a-form-item label="订单类别" name="categoryName">
                        <a-form-item>
                            <SelectInput
                                :title="formData.categoryName"
                                :onClick="() => (orderCategoryVisible = true)"
                                @onDelete="() => onChangeOrderCategory()"
                            ></SelectInput>
                        </a-form-item>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :span="24" class="margin-bottom">
                <a-col :span="12">
                    <a-form-item
                        label="产品名称"
                        name="productName"
                        :rules="[{ required: true, message: '请选择产品' }]"
                    >
                        <a-form-item>
                            <div class="contant-item">
                                <a-input
                                    style="width: 320px"
                                    v-model:value="formData.productName"
                                    :disabled="true"
                                    :maxlength="100"
                                    showCount
                                />
                                <a-button type="primary" @click="() => (productListVisible = true)">
                                    查询
                                </a-button>
                            </div>
                        </a-form-item>
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item
                        label="产品编码"
                        name="productCode"
                        :rules="[{ required: true, message: '请选择产品' }]"
                    >
                        <a-input
                            v-model:value="formData.productCode"
                            :disabled="true"
                            :maxlength="100"
                            showCount
                        />
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :span="24" class="margin-bottom">
                <a-col :span="12">
                    <a-form-item label="规格型号" name="spec">
                        <a-input
                            v-model:value="formData.spec"
                            :disabled="true"
                            :maxlength="100"
                            showCount
                        />
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item
                        label="单位"
                        name="unit"
                        :rules="[
                            { required: true, message: '请输入单位' },
                            { validator: checkSpaceAll },
                        ]"
                    >
                        <a-input
                            v-model:value="formData.unit"
                            :maxlength="100"
                            showCount
                            :disabled="true"
                        />
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :span="24" class="margin-bottom">
                <a-col :span="12">
                    <a-form-item
                        label="生产数量"
                        name="quantity"
                        :rules="[{ required: true, message: '请输入生产数量' }]"
                    >
                        <a-input-number
                            v-model:value="formData.quantity"
                            :min="1"
                            :step="1"
                            :precision="0"
                            style="width: 100%"
                        />
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :span="24" class="margin-bottom">
                <a-col :span="12">
                    <a-form-item label="预计开始日期" name="scheduledStartDate">
                        <a-date-picker
                            v-model:value="formData.scheduledStartDate"
                            valueFormat="YYYY-MM-DD"
                            :disabled-date="disabledStartDate"
                            style="width: 100%"
                        />
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item label="预计完成日期" name="scheduledEndDate">
                        <a-date-picker
                            v-model:value="formData.scheduledEndDate"
                            valueFormat="YYYY-MM-DD"
                            :disabled-date="disabledEndDate"
                            style="width: 100%"
                        />
                    </a-form-item>
                </a-col>
            </a-row>

            <a-form-item
                label="备注"
                name="remarks"
                :label-col="{ span: 3 }"
                :wrapper-col="{ span: 21 }"
                :rules="[{ validator: checkSpaceAll }]"
            >
                <a-textarea
                    v-model:value="formData.remarks"
                    placeholder="最多可输入800字"
                    :rows="4"
                    :maxlength="800"
                />
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 21, span: 5 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiProductOrderGetUpdateData, apiProductOrderSavaUpdateData } from "@/api";
import Select from "@/components/Select";
import ProductList from "@/components/ProductList";
import OrderCategory from "@/components/CategoryModal";
import SaleOrder from "@/components/SaleOrder";
import dayjs from "dayjs";
import { showMessage, checkValueIsAvailable, getAutoCreateCode } from "@/utils/common";
import SelectInput from "@/components/SelectInput";
export default defineComponent({
    props: ["upDateId"],
    emits: ["eventUpdateOk", "update:upDateId"],
    components: {
        Select,
        OrderCategory,
        ProductList,
        SaleOrder,
        SelectInput,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            autoCreateOrder: false,
            saleOrderVisible: false,
            bindSaleOrder: false,
            productListVisible: false,
            orderCategoryVisible: false,
            orderCategoryOptions: [],
            priorityOptinos: [],
            productionLineTypeOptions: [],
            autoCreateCode: "",
            formData: {
                id: "",
                produceOrderCode: "",
                priority: "",
                categoryCode: "",
                categoryName: "",
                productionLineType: "",
                salesOrderCode: "",
                projectName: "",
                projectCode: "",
                productName: "",
                productCode: "",
                spec: "",
                quantity: 0,
                unit: "",
                scheduledStartDate: "",
                scheduledEndDate: "",
                remarks: "",
            },
        });

        const getOrder = async () => {
            let code = await getAutoCreateCode("MES_ORDERS");
            state.autoCreateCode = code;
            state.formData.produceOrderCode = code;
        };

        const handleCancel = () => {
            state.visible = false;
            emit("update:upDateId", null);
        };

        const onFinish = async () => {
            let res = await apiProductOrderSavaUpdateData(state.formData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("eventUpdateOk");
            }
        };

        const getIndexData = async id => {
            let res = await apiProductOrderGetUpdateData(id);
            if (res.status === "SUCCESS") {
                const {
                    mesOrders,
                    categoryCandidate,
                    priorityCandidate,
                    productionLineTypeCandidate,
                } = res?.data;
                state.orderCategoryOptions = categoryCandidate;
                state.priorityOptinos = priorityCandidate;
                state.productionLineTypeOptions = productionLineTypeCandidate;
                Object.keys(state.formData).forEach(key => {
                    state.formData[key] = mesOrders ? mesOrders?.[key] : "";
                });
            }
        };

        const checkSpaceStartEnd = (rule, value) => {
            if (value && !checkValueIsAvailable(value, "spaceStartEnd")) {
                return Promise.reject("不能以空格开头或结尾");
            } else {
                return Promise.resolve();
            }
        };

        const onSelectProduct = ([product]) => {
            state.formData.productCode = product?.code;
            state.formData.productName = product?.name;
            state.formData.unit = product?.primaryUom;
            state.formData.spec = product?.spec;
        };

        watch(
            () => props.upDateId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    getIndexData(newVal);
                } else {
                    state.autoCreateOrder = false;
                    state.autoCreateCode = "";
                    state.visible = false;
                }
            }
        );

        watch(
            () => state.autoCreateOrder,
            newVal => {
                if (newVal && !state.autoCreateCode) {
                    getOrder();
                } else {
                    state.formData.produceOrderCode = state.autoCreateCode;
                }
                if (!newVal) state.formData.produceOrderCode = "";
            }
        );

        const disabledStartDate = current => {
            const { scheduledEndDate } = state.formData;
            if (!scheduledEndDate) {
                return false;
            }
            return current && current >= dayjs(scheduledEndDate).endOf("day");
        };

        const disabledEndDate = current => {
            const { scheduledStartDate } = state.formData;
            if (!scheduledStartDate) {
                return false;
            }
            return current && current < dayjs(scheduledStartDate).startOf("day");
        };

        const checkSpaceAll = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        const changeAutoCreateOrder = value => {
            if (value.target.checked) {
                state.formData.produceOrderCode = "";
            }
        };

        const changeBindSaleOrder = value => {
            if (value.target.checked) {
                state.formData.salesOrderCode = "";
            }
        };

        const onChangeOrderCategory = arr => {
            const [value] = arr || [];
            state.formData.categoryName = value ? value?.name : "";
            state.formData.categoryCode = value ? value?.code : "";
        };

        const onSelectSaleOrder = ([value]) => {
            state.formData.salesOrderCode = value?.code;
            state.formData.projectName = value?.projectName;
            state.formData.projectCode = value?.projectCode;
        };

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkSpaceStartEnd,
            disabledStartDate,
            disabledEndDate,
            checkSpaceAll,
            changeAutoCreateOrder,
            changeBindSaleOrder,
            onSelectProduct,
            onSelectSaleOrder,
            onChangeOrderCategory,
        };
    },
});
</script>

<style lang="less" scoped>
.contant-item {
    display: flex;
    justify-content: space-between;
}
.margin-bottom {
    margin-bottom: 20px;
}
</style>
